<ng-container
    [ngTemplateOutlet]="optionTemplate ?? baseOptionTemplate"
    [ngTemplateOutletContext]="{ label: (label$ | async), value: value, allowMultiple: allowMultiple, selected: selected }"
/>

<ng-template
    #baseOptionTemplate
    let-allowMultiple="allowMultiple"
    let-label="label"
    let-selected="selected"
>
    <div class="d-flex align-items-center">
        @if (allowMultiple) {
            <eb-checkbox-input
                class="mr-2"
                [ngModel]="selected"
            />
        }
        <eb-label
            class="material-input-select-option__label"
            textTransform="capitalize"
            >{{ label }}</eb-label
        >
    </div>
</ng-template>
