import {
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ConnectedPosition,
} from '@angular/cdk/overlay';
import {
    AsyncPipe,
    NgTemplateOutlet,
} from '@angular/common';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    HostListener,
    Input,
    Optional,
    QueryList,
    Self,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    NgControl,
    UntypedFormControl,
} from '@angular/forms';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    combineLatest,
    EMPTY,
    filter,
    fromEvent,
    map,
    merge,
    Observable,
    ReplaySubject,
    startWith,
    Subject,
    Subscription,
} from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DropdownPanelAltComponent } from '../dropdown-panel-alt/dropdown-panel-alt.component';
import { DropdownPanelBodyComponent } from '../dropdown-panel-body/dropdown-panel-body.component';
import { IconComponent } from '../icon/icon.component';
import { MaterialInputSelectOptionComponent } from '../material-input-select-option/material-input-select-option.component';

@UntilDestroy()
@Component({
    selector: 'eb-material-input-select',
    templateUrl: './material-input-select.component.html',
    styleUrls: ['./material-input-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CdkOverlayOrigin,
        NgTemplateOutlet,
        IconComponent,
        CdkConnectedOverlay,
        DropdownPanelAltComponent,
        DropdownPanelBodyComponent,
        AsyncPipe,
    ],
})
export class MaterialInputSelectComponent<T = string> implements AfterViewInit, AfterContentInit {
    @Input()
    public placeholder: string = '';

    @Input()
    public multiple: boolean = false;

    @Input()
    public label: string = '';

    @Input()
    public disabled: boolean = false;

    @Input()
    public editOnClick: boolean = false;

    @Input()
    public labelPosition: 'top' | 'auto' = 'auto';

    @Input()
    public selectedLabelTemplate?: TemplateRef<HTMLElement>;

    @ViewChild('overlay', { static: true })
    public overlay: CdkConnectedOverlay | undefined;

    @ContentChildren(MaterialInputSelectOptionComponent, { descendants: true })
    public options?: QueryList<MaterialInputSelectOptionComponent<T>>;

    public options$ = new Subject<QueryList<MaterialInputSelectOptionComponent<T>>>();

    public isOpen = false;

    public inputFocused = false;

    public positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 14,
            offsetX: 0,
            panelClass: 'dropdown--top',
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: 14,
            offsetX: 0,
            panelClass: 'dropdown--top',
        },
    ];

    public fcValue = new UntypedFormControl();

    public required: boolean = false;

    public selectedLabel?: string;

    public selectedLabel$: Observable<string> = EMPTY;

    public selectedValue?: T | T[] | null;

    public writeValue$ = new ReplaySubject<T | T[] | null>();

    public clickEvents$: Subscription = new Subscription();

    public constructor(
        private cd: ChangeDetectorRef,
        @Self() @Optional() private control: NgControl,
    ) {
        if (this.control) {
            this.control.valueAccessor = this;
        }
    }

    @HostListener('document:click', ['$event'])
    public clickOutsideOverlay(event: PointerEvent) {
        if (!this.isOpen || !this.overlay) {
            return;
        }

        const clickTarget = event.target as HTMLElement;
        const notOrigin = !((this.overlay.origin as CdkOverlayOrigin).elementRef.nativeElement as HTMLElement).contains(clickTarget);
        const notOverlay = !this.overlay.overlayRef.overlayElement.contains(clickTarget);

        if (notOrigin && notOverlay) {
            this.isOpen = false;
            this.cd.markForCheck();
        }
    }

    public ngAfterViewInit(): void {
        this.control?.control?.valueChanges.pipe(
            startWith(this.control?.control),
            filter((control) => control instanceof AbstractControl),
            debounceTime(50),
            untilDestroyed(this),
        )
            .subscribe((control) => {
                this.required = this.hasRequiredField(control);
                this.cd.markForCheck();
            });
    }

    public openOverlay() {
        this.isOpen = true;
        this.cd.markForCheck();
    }

    public focusInput(focused: boolean) {
        this.inputFocused = focused;
        this.cd.markForCheck();
    }

    public hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    public ngAfterContentInit(): void {
        if (!this.options) {
            return;
        }

        combineLatest([
            this.writeValue$.pipe(startWith(null)),
            this.options$,
        ])
            .pipe(
                untilDestroyed(this),
            )
            .subscribe(([value, options]: [T | T[] | null, QueryList<MaterialInputSelectOptionComponent<T>>]) => {
                this.initOptions(options);
                this.selectedValue = value;

                if (Array.isArray(value)) {
                    this.selectedValue = value;
                } else if (value !== null) {
                    this.selectedValue = [value];
                }

                if (this.multiple && Array.isArray(value)) {
                    (options || [])
                        .filter((currentOption) => currentOption.value !== null && value.includes(currentOption.value))
                        .forEach((option) => {
                            option.setSelected(true);
                        });
                } else if (!this.multiple && !Array.isArray(value)) {
                    const option = (options || []).find((currentOption) => currentOption.value === value);
                    if (option) {
                        option.setSelected(true);
                    }
                }

                this.setSelectedLabel();
                this.cd.detectChanges();
            });

        if (this.options) {
            this.options.changes
                .pipe(untilDestroyed(this))
                .subscribe((options: QueryList<MaterialInputSelectOptionComponent<T>>) => {
                    this.options$.next(options);
                });
            this.options$.next(this.options);
        }
    }

    public initOptions(options: QueryList<MaterialInputSelectOptionComponent<T>>) {
        const events = options
            .map(
                (option) => fromEvent(option.elementRef.nativeElement, 'click')
                    .pipe(
                        map(() => ({
                            label: option.label,
                            value: option.value,
                        })),
                    ),
            );

        options.forEach((option) => option.setAllowMultiple(this.multiple));

        // Avoid multiple subscribtion on click event
        this.clickEvents$.unsubscribe();

        this.clickEvents$ = merge(...events)
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((value) => {
                if (this.multiple) {
                    this.setSelectedItems(value.value);
                } else {
                    this.setSelectedItem(value.value);
                }
            });
    }

    public setSelectedItem(value: T | null) {
        if (this.options) {
            this.options.forEach((option) => {
                option.setSelected(option.value === value);
            });
        }
        this.setSelectedLabel();
        this.setSelectedValue();
        this.isOpen = false;
        this.onTouched();
        this.cd.detectChanges();
    }

    public setSelectedItems(value: T | null) {
        if (this.options) {
            const option = this.options.find((currentOption) => currentOption.value === value);

            if (option) {
                option.setSelected(!option.selected);
            }
        }
        this.setSelectedLabel();
        this.setSelectedValue();
        this.cd.detectChanges();
    }

    public setSelectedLabel() {
        if (this.options) {
            const options = this.options.filter((currentOption) => currentOption.selected);

            this.selectedLabel$ = combineLatest([
                ...options.map((currentOption) => currentOption.label$),
            ]).pipe(
                map((labels) => labels.join(', ')),
            );
        }
    }

    public setSelectedValue() {
        if (this.options) {
            const options = this.options.filter((currentOption) => currentOption.selected);

            this.selectedValue = options
                .map((currentOption) => currentOption.value)
                .filter((item): item is T => !!item);

            if (this.selectedValue.length === 0) {
                this.onChange(null);
            } else if (this.selectedValue.length > 0 && this.multiple) {
                this.onChange(this.selectedValue);
            } else if (this.selectedValue.length > 0 && !this.multiple) {
                this.onChange(this.selectedValue[0]);
            }
        }
    }

    writeValue(obj: T | T[] | null): void {
        this.writeValue$.next(obj);
    }

    registerOnChange(fn: (value: T | T[] | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private onChange: ((value: T | T[] | null) => void) = () => {};

    private onTouched: () => void = () => {};
}
