import {
    AsyncPipe,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    HostBinding,
    Input,
    TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { CheckboxInputComponent } from '../checkbox-input/checkbox-input.component';
import { LabelComponent } from '../label/label.component';

@Component({
    selector: 'eb-material-input-select-option',
    templateUrl: './material-input-select-option.component.html',
    styleUrls: ['./material-input-select-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        CheckboxInputComponent,
        FormsModule,
        LabelComponent,
        AsyncPipe,
    ],
})
export class MaterialInputSelectOptionComponent<T = string> {
    @Input()
    public value: T | null = null;

    @Input()
    public set label(value: string) {
        this.label$.next(value);
    }

    @ContentChild('optionTemplate')
    public optionTemplate?: TemplateRef<HTMLElement>;

    @HostBinding('class.material-input-select-option--selected')
    @Input()
    public selected: boolean = false;

    public label$ = new BehaviorSubject<string>('');

    public allowMultiple = false;

    public constructor(
        public elementRef: ElementRef<HTMLElement>,
        private cd: ChangeDetectorRef,
    ) {}

    public setSelected(selected: boolean) {
        this.selected = selected;
        this.cd.markForCheck();
    }

    public setAllowMultiple(allowMultiple: boolean) {
        this.allowMultiple = allowMultiple;
        this.cd.markForCheck();
    }
}
