<div
    #origin
    #selectGroup
    #trigger="cdkOverlayOrigin"
    class="material-input-select__group"
    [class.material-input-select__group--disabled]="disabled"
    [class.material-input-select__group--has-value]="
        (!multiple && selectedValue && selectedValue.length === 1) || (multiple && selectedValue && selectedValue.length > 0) || inputFocused || labelPosition === 'top'
    "
    [class.material-input-select__group--readonly]="editOnClick"
    (focusin)="openOverlay()"
    cdkOverlayOrigin
>
    <div class="material-input-select__label"
        >{{ label }}
        @if (required) {
            <span class="material-input-select__label-required">*</span>
        }
    </div>
    <div
        class="material-input-select__input"
        [class.material-input-select__input--placeholder]="!selectedValue || !(selectedLabel$ | async)"
        (click)="openOverlay()"
    >
        @if (selectedValue && selectedLabel$ | async; as selectedLabel) {
            <ng-container
                [ngTemplateOutlet]="selectedLabelTemplate ?? baseSelectedLabelTemplate"
                [ngTemplateOutletContext]="{ label: selectedLabel, value: selectedValue }"
            />
        } @else {
            {{ placeholder }}
        }
    </div>
    <div
        class="material-input-select__arrow"
        (click)="openOverlay()"
    >
        <eb-icon
            [width]="24"
            icon="ui/arrow/simple/down"
        />
    </div>
    <div class="material-input-select__bar"></div>
</div>
<ng-template
    #overlay="cdkConnectedOverlay"
    [cdkConnectedOverlayDisableClose]="true"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayWidth]="origin.offsetWidth"
    cdkConnectedOverlay
>
    <eb-dropdown-panel-alt
        [style.width]="selectGroup.clientWidth + 'px'"
        [radius]="true"
    >
        <eb-dropdown-panel-body class="material-input-select__dropdown-panel-body">
            <div class="p-3">
                <ng-content />
            </div>
        </eb-dropdown-panel-body>
    </eb-dropdown-panel-alt>
</ng-template>

<ng-template
    #baseSelectedLabelTemplate
    let-label="label"
    let-value="value"
>
    {{ label }}
</ng-template>
